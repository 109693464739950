
import {config} from '../Constants';
import {DriverProfile} from './model/DriverProfile';
import {ApiCommand} from './ApiCommand';
import {LoadDetails} from "./model/LoadDetails";
import {PhotoList} from "./model/PhotoList";
import {TrainingReports} from "./model/TrainingReports";
import {TrainingReportBacking} from "./model/TrainingReportBacking";
import {DocumentInfo} from "./model/DocumentsList";
import {toastStore} from "../components/AppToast";
import {sendErrorMsg} from "../components/DiscordHookError";
import {LaredoInspectionSubmitResult} from "./model/LaredoInspectionSubmitResult";
import {Leaderboard} from "./model/Leaderborad";
import {UserDetails} from "./model/UserDetails";
import {PushNotification} from "./model/PushNotification";
import {TrainingReportGet2} from "./model/TrainingReportGet2";
import {PreplannedLoad} from "./model/PreplannedLoad";
import {VideoInfo} from "./model/VideosList";
import {Trainee} from "./model/Trainee";
import {ContactsInfo} from "./model/Contacts";
import {DriverManager} from "./model/DriverManager";
import {TrainingCourse} from "./model/TrainingCourses";
import {TrainingPrompt} from "./model/TrainingPrompt";
import {Drivers} from "./model/Drivers";
import {PlatformUtils} from "../utils/PlatformUtils";
import {PayrollOverview} from "./model/PayrollOverview";
import {GamificationDriver2} from "./model/GamificationDriver2";
import {SentMessageResponse} from "./model/SentMessageResponse";
import {CashAdvance} from "./model/CashAdvance";
import {UserStateResponse} from "./model/UserStateResponse";
import {CashAdvanceEligibility} from "./model/CashAdvanceEligibility";
import {PaycheckHistory} from "./model/PaycheckHistory";
import {PaycheckDetail} from "./model/PaycheckDetail";
import {OrientationDriverList} from "./model/OrientationDriverList";
import {NewsArticle} from "./model/NewsArticle";
import {LoadHistoryInfo} from "./model/LoadHistory";
import {InboxMessage} from "./model/InboxMessage";
import {FaqEntry} from "./model/FaqEntry";
import {GenericReportSubmissionResult} from "./model/GenericReportSubmissionResult";
import {TrailerLocation} from "./model/TrailerLocation";
import {AuthService, userSessionStore} from "./AuthService";
import {LoginResponse} from "./model/LoginResponse";
import {loadingStore} from "../components/AppLoading";
import {TrainerFeedbackRequest} from "./model/request/TrainerFeedbackRequest";
import {SafetyScorecardInfo} from "./model/SafetyScorecardResponse";
export class ApiService {
    private static fetch(page: string, cmd: string, args: object, files?: any, hideErrors?: boolean): Promise<any> {
        if(cmd !== 'routeCompute'){
            loadingStore.getState().increaseCount();
        }
        userSessionStore.getState().setSessionTimer();
        const data = new FormData();
        data.append("payload", JSON.stringify({cmds: [{cmd, args}]}));
        if(files) {
            for (const key in files) {
                data.set(`${files[key].fileName}`, files[key].file, files[key].fileName);
            }
        }
        const requestOptions = {method: 'POST', headers: {}, body: data};
        return  fetch(config.url.BASE_URL, requestOptions)
            .then(response => response.json())
            .then(async it => {
                if (it.results[0].errcode === 'NOTLOGGEDIN') {
                    this.userLogout(page);
                } else if (it.results[0].errcode === 'SYSERR') {
                    sendErrorMsg(`SYSERR: ${cmd} - app`);
                    await ApiService.sendEventCommand(page, cmd, args);
                    throw it.results[0].errcode;
                } else if (it.results[0].errmsg) {
                    if (!hideErrors) {
                        toastStore.getState().setToast(it.results[0].errmsg);
                    }
                    await ApiService.sendEventCommand(page, cmd, args);
                    throw it.results[0];
                }
                await ApiService.sendEventCommand(page, cmd, args);
                return it.results[0];
            }).finally(() => {
                if(cmd !== 'routeCompute'){
                    loadingStore.getState().decreaseCount();
                }
            });
    }

    public static async sendEventCommand(page: string, cmd: string, args: {}) {
        let argus: any = {type: cmd, eventData: JSON.stringify(args), userId: localStorage.getItem('userId'), subtype: page, loginToken: localStorage.getItem('token')}
        if(localStorage.getItem("dmUsername") !== null) {
            argus.dmCode = localStorage.getItem("dmUsername");
        }
        const data = new FormData();
        data.append("payload", JSON.stringify({ cmds: [{cmd: "eventLog", args: argus}] }))
        const requestOptions = {method: 'POST', headers: {}, body: data};
        fetch(config.url.BASE_URL, requestOptions)
        .then(response => response.json())
        .then(json => {console.log("json results", json.results[0])})
    }

    public static getDriverProfile(page: string, driverCode = localStorage.getItem('username')?.toUpperCase()): Promise<DriverProfile> {
        return ApiService.fetch(page, ApiCommand.DRIVER_PROFILE, {
            driverCode,
            loginToken: localStorage.getItem('token')
        });
    }

    public static getUser(page: string, username = localStorage.getItem('username')?.toUpperCase()): Promise<UserDetails> {
        return ApiService.fetch(page, ApiCommand.USER_GET, {
            username,
            loginToken: localStorage.getItem('token')
        });
    }

    public static getLoadDetails(page: string, args: { unit?: string, orderNumber?: string }): Promise<LoadDetails> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.LOAD_DETAILS, args);
    }

    public static getLoadHistory(page: string, args: { start: number, length: number }, hideErrors?: boolean): Promise<LoadHistoryInfo[]> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.LOAD_HISTORY, args, hideErrors).then(it => it.loads);
    }

    public static getPhotoList(page: string, args: { load?: number, type?: string, refId?: string }): Promise<PhotoList> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.PHOTO_LIST, args);
    }

    public static getTrainees(page: string, args: { released?: number }): Promise<Trainee[]> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).trainerCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.TRAINEE_SEARCH, args).then(it => it.trainees);
    }

    public static getTraineeProfile(page: string, traineeCode = localStorage.getItem('username')?.toUpperCase()): Promise<Trainee> {
        return ApiService.fetch(page, ApiCommand.TRAINEE_PROFILE, {
            traineeCode,
            loginToken: localStorage.getItem('token')
        }).then(it => it.trainee);
    }

    public static claimTrainee(page: string, args: { traineeCode: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).trainerCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.TRAINEE_CLAIM, args);
    }

    public static getTrainingReports(page: string, args: { backingEvents: string, traineeCode: string }): Promise<TrainingReports> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINING_REPORTS_GET, args);
    }

    public static getTrainingReports2(page: string, args: { traineeCode: string }, hideErrors?: boolean): Promise<TrainingReports> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINING_REPORTS_GET_2, args, hideErrors);
    }

    public static getTrainingReportBacking(page: string, args: { traineeCode: string, timeSubmitted: string }): Promise<TrainingReportBacking> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINING_REPORT_BACKING, args);
    }

    public static getDriverSsn(page: string): Promise<string> {
        return ApiService.fetch(page, ApiCommand.DRIVER_SSN, {
            driverCode: localStorage.getItem('username')?.toUpperCase(),
            loginToken: localStorage.getItem('token')
        }).then(it => it.ssn);
    }

    public static getDocsList(page: string, args: { type: string, isLp: number }): Promise<DocumentInfo[]> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.DOCS_LIST, args).then(it => it.documents);
    }

    public static getVideosList(page: string, args: { type: string }): Promise<VideoInfo[]> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.VIDEOS_GET, args).then(it => it.videos);
    }

    public static sendEmail(page: string, args: { to: string[], subject: string, body: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.GENERIC_EMAIL, args);
    }

    public static sendFileEmail(page: string, args: {
        email: string,
        subject: string
    },
    files: {
        file1: {
            fileName: string,
            file: any,
        },
        file2?: {
            fileName?: string,
            file?: any
        }
    }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.GENERIC_FILE_EMAIL, args, files);
    }

    public static laredoInspectionSubmit(page: string, args: { trailer: string, techCode: string, reportType: string, reportData: string }): Promise<LaredoInspectionSubmitResult> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.LAREDO_INSPECTION_SUBMIT, args);
    }
    public static laredoInspectionGet(page: string, args: { trailer: string, reportType: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.LAREDO_INSPECTION_GET, args);
    }

    public static userLogout(page: string): void {
        ApiService.fetch(page, ApiCommand.USER_LOGOUT, {
            loginToken: localStorage.getItem('token')
        }).finally(() => AuthService.processLogout());
    }

    public static loginAsDriver(page: string, args: { driverCode: string }): void {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).platform = PlatformUtils.getCurrentPlatform(true);
        ApiService.fetch(page, ApiCommand.LOGIN_AS_DRIVER, args).then((it) => {
            AuthService.processLogin(it, {username: args.driverCode, password: ''}, {agreedToTerms: true, saveCredentials: false});
        });
    }

    public static getLeaderboard(page: string, args: { span: string, type: string }): Promise<Leaderboard[]> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.LEADERBOARD, args).then(it => it.leaderboard);
    }

    public static registerDeviceToken(page: string, args: { platform: string, userId: string}): Promise<void> {
        (args as any).token = localStorage.getItem('pushNotificationPermission');
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.REGISTER_DEVICE_TOKEN, args)
    }

    public static getPushNotifications(page: string): Promise<PushNotification[]> {
        return ApiService.fetch(page, ApiCommand.PUSH_NOTIFICATIONS_GET, {
            driverCode: localStorage.getItem('username')?.toUpperCase(),
            loginToken: localStorage.getItem('token')
        }).then(it => it.pushNotifications);
    }

    public static readPushNotification(page: string, args: { id: number }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.PUSH_NOTIFICATION_READ, args);
    }

    public static getTrainingReport2(page: string, args: { traineeCode: string, timeSubmitted: string }): Promise<TrainingReportGet2> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINING_REPORT_GET_2, args).then(it => it.report);
    }

    public static getPreplannedLoads(page: string): Promise<PreplannedLoad[]> {
        return ApiService.fetch(page, ApiCommand.PREPLANNED_LOADS_GET, {
            driverCode: localStorage.getItem('username')?.toUpperCase(),
            loginToken: localStorage.getItem('token')
        }, true).then(it => it.loads);
    }

    public static submitTrainerFeedback(page: string, args: TrainerFeedbackRequest): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINER_FEEDBACK, args);
    }

    public static trainerAgreement(page: string, args: {trainerCode: string, signed: number}) {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINER_AGREEMENT, args);
    }

    public static getContacts(page: string, args: { category: string }): Promise<ContactsInfo[]> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.CONTACTS_GET, args).then(it => it.contacts);
    }
    public static getDriverManagers(page: string): Promise<DriverManager[]> {
        return ApiService.fetch(page, ApiCommand.DM_GET, {
            loginToken: localStorage.getItem('token')
        }).then(it => it.dms);
    }

    public static getDriverManager(page: string, args: { dmCode: string }): Promise<DriverManager> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.DM_GET, args).then(it => it.dms[0]);
    }

    public static getManagerDrivers(page: string, dmCode = localStorage.getItem('dmUsername')): Promise<Drivers> {
        return ApiService.fetch(page, ApiCommand.DM_DRIVERS_GET, {
            dmCode,
            loginToken: localStorage.getItem('token')
        }).then(it => it.drivers);
    }

    public static getTrainingReportDayPassed2(page: string, args: { traineeCode: string }): Promise<boolean> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINING_REPORT_DAY_PASSED_2, args).then(it => it.isNewDay);
    }

    public static getTrainingCourse(page: string, args: { id: number }): Promise<TrainingCourse> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINING_COURSE_GET, args);
    }

    public static getTrainingCourses(page: string): Promise<TrainingCourse[]> {
        return ApiService.fetch(page, ApiCommand.TRAINING_COURSES_GET, {
            loginToken: localStorage.getItem('token')
        }).then(it => it.courses);
    }

    public static getTrainingPrompts(page: string, args: { currentCourseId: number, currentCourseDay: number }): Promise<TrainingPrompt> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINING_PROMPTS_GET, args);
    }

    public static assignTraineeCourse(page: string, args: { traineeCode: string, courseId: number }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINEE_COURSE_ASSIGN, args);
    }

    public static submitTrainingReport2(page: string, args: {
        dmCode: string,
        traineeDrove: number,
        traineeCode: string,
        courseId: number,
        courseDay: number,
        reportData: string,
        overallScore: number,
        traineeAccident: number,
        traineeReady: number,
        roadTest: number,
        reportId: number,
        loadSecurement: number,
    }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).trainerCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.TRAINING_REPORT_SUBMIT_2, args);
    }

    public static submitRoadTest(page: string, args: {
        traineeCode: string,
        trainerCode: string,
        starting: string,
        truckBacking: string,
        hookUp: string,
        preTripInspec: string,
        rampSpeed: string,
        onUpgrade: string,
        lanePos: string,
        shifting: string,
        useHorn: string,
        intersectionSpeed: string,
        distraction: string,
        laneControl: string,
        seatBelt: string,
        trafficLight: string,
        stopSign: string,
        otherSign: string,
        coastingOutGear: string,
        laneChange: string,
        overTaking: string,
        unsafeDrivingAction: string,
        followDistance: string,
        useMirrors: string,
        rightOfWay: string,
        controlSpeed: string,
        startStop: string,
        highwaySignal: string,
        highwaySpeed: string,
        highwayApproach: string,
        highwayEnter: string,
        highwayInterObserv: string,
        backingSetup: string,
        backingSteering: string,
        backingHorn: string,
        backing4Way: string,
        citySignal: string,
        citySpeed: string,
        cityApproach: string,
        cityEnter: string,
        cityInterObserv: string,
        grade: string
    }): Promise<string> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.ROAD_TEST_SUBMIT, args).then(it => it.reportId);
    }

    public static traineeRelease(page: string, args: {
        traineeCode: string,
        releasedDate: string,
        trainerCode: string,
        currentCourseDay: number,
        passed: number,
        failureReason: string
    }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAINEE_RELEASE, args);
    }

    public static vehicleRegister(page: string, args: { plateNumber: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.VEHICLE_REGISTER, args);
    }

    public static getPayrollOverview(page: string): Promise<PayrollOverview> {
        return ApiService.fetch(page, ApiCommand.PAYROLL_OVERVIEW, {
            driverCode: localStorage.getItem('username')?.toUpperCase(),
            loginToken: localStorage.getItem('token')
        }, true, true);
    }

    public static getGamificationDriver2(page: string): Promise<GamificationDriver2> {
        return ApiService.fetch(page, ApiCommand.GAMIFICATION_DRIVER_2, {
            username: localStorage.getItem('username')?.toUpperCase(),
            driverCode: localStorage.getItem('username')?.toUpperCase(),
            loginToken: localStorage.getItem('token')
        }, true, true);
    }

    public static inboxSendMessage(page: string, args: { id?: number, toUserId?: string, body?: string, subject?: string, type?: string, conversationId?: number, fromUserId?: string }): Promise<SentMessageResponse> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.INBOX_SEND_MESSAGE, args);
    }

    public static inspectionSubmission(page: string, args: { dmCode: string, division: string, load: string, unit: string, trailer: string, reportData: string, type: string, photos: number }): Promise<number> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.INSPECTION_SUBMISSION, args).then(it => it.inspectionId);
    }

    public static submitCashAdvance(page: string, args: { amount: number, comment: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.CASH_ADVANCE, args);
    }

    public static getCashAdvances(page: string): Promise<CashAdvance[]> {
        return ApiService.fetch(page, ApiCommand.CASH_ADVANCES_GET, {
            driverCode: localStorage.getItem('username')?.toUpperCase(),
            loginToken: localStorage.getItem('token')
        }).then(it => it.advances);
    }

    public static getCashAdvanceEligibility(page: string): Promise<CashAdvanceEligibility> {
        return ApiService.fetch(page, ApiCommand.CASH_ADVANCE_ELIGIBILITY, {
            driverCode: localStorage.getItem('username')?.toUpperCase(),
            loginToken: localStorage.getItem('token')
        });
    }

    public static getUserState(page: string, args: { name: string, userId: number }): Promise<UserStateResponse> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.USER_STATE_GET, args);
    }

    public static getPaycheckHistory(page: string, args: { start: number, length: number }): Promise<PaycheckHistory[]> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.PAYCHECK_HISTORY, args).then(it => it.paychecks);
    }

    public static getPaycheckDetail(page: string, args: { checkDate: string }): Promise<PaycheckDetail> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.PAYCHECK_DETAIL, args);
    }

    public static updateOrientation(page: string, args: { ssn: string, passed: number, completed: number, disqualified?: number }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.ORIENTATION_UPDATE, args);
    }

    public static submitOrientationEval(page: string, args: {
        name: string,
        governmentId: string,
        instructor: string,
        reportData: string,
        passed: number,
        score: number,
        timeIn: string,
        timeOut: string,
        cdl: number,
        medcert: number,
        evalType: string,
        notes: string
    }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.ORIENTATION_EVAL_SUBMIT, args);
    }

    public static getOrientationDriverList(page: string, args: {
        disqualified: number,
        startTime: string,
        endTime: string,
        start: number,
        length: number,
        terminal: string,
    }): Promise<OrientationDriverList[]> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.ORIENTATION_DRIVER_LIST, args).then(it => it.drivers);
    }

    public static submitTenstreetDocument(page: string, args: {
        ssn: string,
        fileId: number,
        fileCode: string,
        fileStorageType: string,
        fileCategoryName: string,
        filename: string,
        fileSize: number,
        fileType: string,
        fileData: string,
        mode: string
    }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TENSTREET_DOCUMENT, args);
    }

    public static getNewsArticles(page: string): Promise<NewsArticle[]> {
        return ApiService.fetch(page, ApiCommand.NEWS_ARTICLES_GET, {
            loginToken: localStorage.getItem('token')
        }).then(it => it.articles);
    }

    public static getAllInboxMessages(page: string, args: { withBodies: number, start?: number, length?: number, conversationId?: number }): Promise<InboxMessage[]> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.INBOX_GET_ALL_MESSAGES, args).then(it => it.messages);
    }

    public static sendHelpMessage(page: string, args: { unit: string, message: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.HELP_REQUEST, args);
    }

    public static getFaqEntries(page: string): Promise<FaqEntry[]> {
        return ApiService.fetch(page, ApiCommand.FAQ_ENTRIES_GET, {
            loginToken: localStorage.getItem('token')
        }).then(it => it.entries);
    }

    public static photoSubmission(page: string, args: {
        type: string,
        refId: string,
        dmCode: string,
        division: string,
        unit: string,
        trailer: string,
        load: string,
        dispatchNumber?: string,
        uploaderType?: string,
    }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.PHOTO_SUBMISSION, args);
    }

    public static trailerReject(page: string, args: { trailerNumber: string, rejectionType: string, comment: string, unitNumber: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.TRAILER_REJECT, args);
    }

    public static driverProfileUpdate(page: string, args: { phone: string, email: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.DRIVER_PROFILE_UPDATE, args);
    }

    public static lpInspectionSubmission(page: string, args: { dmCode: string, unit: number, reportData: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.LP_INSPECTION_SUBMISSION, args);
    }

    public static genericReportSubmission(page: string, args: { load: string, trailer: string, unit: string, comment: string }): Promise<GenericReportSubmissionResult> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.GENERIC_REPORT_SUBMISSION, args);
    }

    public static getTrailerLocation(page: string, args: { trailerNumber: string }): Promise<TrailerLocation> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAILER_LOCATION, args);
    }

    public static trailerConnect(page: string, args: { trailerNumber: string, unitNumber: string }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.TRAILER_CONNECT, args);
    }

    public static userCreate(page: string, args: { username: string, email: string, password: string, phone: string, type: string }): Promise<void> {
        return ApiService.fetch(page, ApiCommand.USER_CREATE, args);
    }

    public static userUpdate(page: string, args: { username: string, signedAgreements: number }): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.USER_UPDATE, args);
    }

    public static userLogin(page: string, args: { username: string, password: string }, loginData: { agreedToTerms: boolean, saveCredentials: boolean }): Promise<any> {
        return ApiService.fetch(page, ApiCommand.USER_LOGIN, args).then((it: LoginResponse) => {
            if (loginData.agreedToTerms || it.signedAgreements === 1) {
                AuthService.processLogin(it, args, loginData);
            }
            return it;
        });
    }

    public static userGetResetToken(page: string, args: { username: string }): Promise<void> {
        return ApiService.fetch(page, ApiCommand.USER_GET_RESET_TOKEN, args);
    }

    public static userResetTokenVerify(page: string, args: { username: string, resetToken: string }): Promise<boolean> {
        return ApiService.fetch(page, ApiCommand.USER_RESET_TOKEN_VERIFY, args).then(it => it.match);
    }

    public static userResetPassword(page: string, args: {username: string, resetToken: string, password: string}): Promise<void> {
        return ApiService.fetch(page, ApiCommand.USER_RESET_PASSWORD, args);
    }
    
    public static photoChecksum(page: string, args: {checksum: string}): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.PHOTO_CHECKSUM, args);
    }

    public static photoChecksumRevoke(page: string, args: {checksum: string}): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.PHOTO_CHECKSUM_REVOKE, args);
    }

    public static safetyScorecard(page: string, args: {}): Promise<SafetyScorecardInfo> {
        (args as any).loginToken = localStorage.getItem('token');
        (args as any).driverCode = localStorage.getItem('username')?.toUpperCase();
        return ApiService.fetch(page, ApiCommand.SAFETY_SCORECARD, args).then(it => it.scorecard);
    }
    
    public static ydVehiclesByTerminal(page: string, args: {terminal: string}): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.YD_VEHICLES_BY_TERMINAL, args)
    }

    public static ydInsert(page: string, args: {terminal: string, unitNumber: string, unitType: string, comments: string, onYard: number, kotl: number, loaded: number, issue: string}): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.YD_INSERT, args)
    }

    public static ydLatest(page: string, args: {terminal: string}): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.YD_LATEST, args)
    }

    public static trailerCustomerPoly(page: string, args: {customerCode: string}): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAILER_CUST_POLY, args)
    }

    public static routeCompute(page: string, args: {startLati: string, startLong: string, destLati: string, destLong: string, travelMode: string}): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.ROUTE_COMPUTE, args)
    }

    public static trailerLocationGet(page: string, args: {unitNumber: string, orderNumber: string}, hideErrors?: boolean): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.TRAILER_LOCATION_GET, args)
    }

    public static checkForBroadcast(page: string): Promise<void> {
        return ApiService.fetch(page, ApiCommand.BROADCAST_MESSAGE_GET, {
            loginToken: localStorage.getItem('token')
        }).then(it => it);
    }

    public static getRegistrations(page: string, args: {unitNumber: string}, hideErrors?: boolean): Promise<void> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.SYNERGIZE_UNIT_DOCUMENTS_GET, args)
    }

    public static echoTest(page: string): Promise<void> {
        return ApiService.fetch(page, ApiCommand.ECHO_TEST, {
            token: localStorage.getItem('pushNotificationPermission'),
            loginToken: localStorage.getItem('token')
        }).then(it => it);
    }

    public static pollResultSubmit(page: string, args: { responseId: number, answer: string }): Promise<TrainingReportGet2> {
        (args as any).loginToken = localStorage.getItem('token');
        return ApiService.fetch(page, ApiCommand.POLL_RESULT_SUBMIT, args).then(it => it.report);
    }

}