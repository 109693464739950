import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {closeSharp} from 'ionicons/icons';
import {config} from '../Constants'
import documentIcon from "../imgs/document.png"
import {ApiService} from "../api/ApiService";
import {getDocument} from 'pdfjs-dist';
import {DocumentInfo} from "../api/model/DocumentsList";
import {VideoInfo} from "../api/model/VideosList";

const SafetyDocs : React.FC = () => {

  const [showModalDoc, setShowModalDoc] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false)
  const [doc, setDoc] = useState<DocumentInfo>();
  const [docs, setDocList] = useState<DocumentInfo[]>([]);
  const [vidLink, setVidLink] = useState("");
  const [vidIds, setVidIds] = useState<VideoInfo[]>();
  const [focusedVid, setFocusedVid] = useState<any>("");
  const [docPage, setDocPage] = useState(1);
  const [selectedDocPages, setSelectedDocPages] = useState<any>([]);
  const [vidElement, setVidElement] = useState<any>();

  useEffect(() => {
    ApiService.getDocsList("safety", {type: 'safety', isLp: 0}).then(it => setDocList(it));
    ApiService.getVideosList("safety", {type: 'safety'}).then(it => setVidIds(it)); 
    checkLink();
  }, []);

  function checkLink() {
    let safetyUrl: any = window.location.href.toString()
    if (safetyUrl?.slice(-6) !== 'safety') {
      setVidLink('https://www.youtube.com/embed/' + safetyUrl.slice(-11))
      setShowModalVideo(true);
    } else {
      console.log("not a url");
      return;
    }
  }

  function prepDoc(doc: any) {
    let tempArray: number[] = Array.from(Array(doc.pages).keys())
    setSelectedDocPages(tempArray);
    console.log("prepped pages", doc, tempArray);
    setShowModalDoc(true);
}

  return (
    <IonPage >
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Safety</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent  fullscreen>

        <IonModal isOpen={showModalDoc} cssClass='my-custom-class'>
          <IonHeader >
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <button onClick={ function() { setShowModalDoc(false); }} style={{background: "transparent"}}>
                  <h6>Back</h6>
                </button>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div style={{ height: '100vh'}}>
                  {/* <img src={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+"1"+'.'+doc?.extension}/> */}
                {selectedDocPages.map(function(pages: number) {
                  return <img src={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+pages+'.'+doc?.extension} alt={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+pages+'.'+doc?.extension} style={{ height: 'auto', width: '100%', zoom:"10%"}}/>
                    {/* <Markup content={doc?.content} /> */}
                })}
              </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showModalVideo} cssClass='my-custom-class'>
        <IonHeader >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <button onClick={ function() { setShowModalVideo(false); setVidLink("")}} style={{background: "transparent"}}>
                <h6>Back</h6>
              </button>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent>
          <iframe id="video-modal" title="safety video" width="100%" height="40%" src={vidLink}>
                </iframe>
          </IonContent>

        </IonModal>
        <div className='main-30-70'>
          <div>
       <IonList style={{ padding: '8px'}}>
       <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
       {docs.map(function(doc, idx){
         let id = doc.id
         let title = doc.title
          return <IonItem key={id} onClick={() => {setDoc(doc); prepDoc(doc); }} lines="none">
              <div className='item-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <p>{title}</p>
                <img src={documentIcon} className="link-icon" alt="doc"/>
              </div>
              {/* <IonLabel text-wrap>{title}</IonLabel>
              <IonIcon slot="end" icon={chevronForward}></IonIcon> */}
            </IonItem>
        })} 
        </IonList></div>
        <div>
       <h1 style={{textAlign: 'center', color: 'black'}}>Videos</h1>
       <div className='video-gal'>{vidIds?.map(function(vidIds, idx){
         if (vidIds.active === 1) {
          console.log(vidIds)
         let src = "https://img.youtube.com/vi/" + vidIds.youtube + "/0.jpg"
         let vidLink = 'https://www.youtube.com/embed/' + vidIds.youtube
            return (
               <div className='video-box' key={idx} onClick={ function() { setShowModalVideo(true); setVidLink(vidLink)}}>
       
                {/* <img alt="youtube video thumbnail" src={src}/> */}
                <iframe id={vidIds.youtube} title="safety video" height="200" src={vidLink}>
                </iframe>
                <p className='title'>{vidIds.title}</p>
            
              {/* <IonLabel text-wrap>{vidIds.title}</IonLabel> */}
              </div>)
         }
        })}</div></div>
        </div>
      
      
      </IonContent>
    </IonPage>
  );
};

export default SafetyDocs;

