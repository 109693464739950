import {config} from "../Constants";
import {PhotoUploadRequest} from "./model/PhotoUploadRequest";
import { base64FromPath } from '@ionic/react-hooks/filesystem';

export class PhotoApiService {

    public static b64toBlob = (base64:any) => 
    fetch(base64).then(res => res.blob())

    public static async uploadPhotos(photos: PhotoUploadRequest[], refId: string, uploadType: string, pictureSerial?: boolean){
      console.log("photos to upload", photos);
      var data = new FormData();
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoUpload", args: {loginToken: localStorage.getItem('token'), refId: refId, type: uploadType}}] }))
      for (let i = 0; i < photos.length; i++) {
        data.append(`photos${i}`, new Blob([(await PhotoApiService.b64toBlob(photos[i].data))],{type: 'application/octet-stream'}));
      }
      const requestOptions = {
        method: 'POST',
        body: data
      };
      return fetch(config.url.BASE_URL, requestOptions)
    }

}
