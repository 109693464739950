import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonButtons, IonBackButton, IonAvatar } from '@ionic/react';
import { ApiService } from '../api/ApiService';
import { DocumentInfo } from '../api/model/DocumentsList';
import { VideoInfo } from '../api/model/VideosList';
import { EMLINK } from 'constants';
import { userStore, UserState } from '../api/AuthService';
import { DriverProfile } from '../api/model/DriverProfile';


const DriverHandbook: React.FC = () => {

    const [showModal, setShowModal] = useState(false);
    const [showModalDoc, setShowModalDoc] = useState(false);
    const [vidLink, setVidLink] = useState("");
    const [docs, setDocList] = useState<DocumentInfo[]>([]);
    const [doc, setDoc] = useState<DocumentInfo>();
    const [selectedDocPages, setSelectedDocPages] = useState<any>([])
    const [vidIds, setVidIds] = useState<VideoInfo[]>();

    const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));

    useEffect(() => {
        ApiService.getDocsList("driverhandbook", {type: 'hr', isLp: 0}).then(it => prepDoc(it));
    }, [])

    function prepDoc(doc: any) {
        doc.forEach((element: any) => {
        console.log("docs", doc, doc[1].title.includes("Driver Handbook"));
        if (element.title === "Driver Handbook CA" && userInfo.division !== "800") {
            let tempArray: number[] = Array.from(Array(element.pages).keys())
            setSelectedDocPages(tempArray);
            setDoc(element);
            console.log("prepped pages", element, tempArray);
            setShowModalDoc(true);
        } else if (element.title === "Driver Handbook" && userInfo.division === "800") {
            let tempArray: number[] = Array.from(Array(element.pages).keys())
            setSelectedDocPages(tempArray);
            setDoc(element);
            console.log("prepped pages", element, tempArray);
            setShowModalDoc(true);
        }
    });
}

  return (
    <IonPage>
        <IonHeader >
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/" />
                </IonButtons>
                <IonTitle>Driver Handbook</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <div style={{ height: '100vh'}}>
                {/* <img src={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+"1"+'.'+doc?.extension}/> */}
                {selectedDocPages.map(function(pages: number) {
                return <img src={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+pages+'.'+doc?.extension} alt={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+pages+'.'+doc?.extension} style={{ height: 'auto', width: '100%', zoom:"10%"}}/>
                    {/* <Markup content={doc?.content} /> */}
                })}
            </div>
        </IonContent>
    </IonPage>
  );
};

export default DriverHandbook;
