import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

import Inbox from './Inbox';
import { checkOS } from '../components/OSCheck';

import AccidentInfo from './AccidentInfo';
import Breakdown from './Breakdown';
import ChainOfCommand from './ChainOfCommand';
import CurrentLoad from './CurrentLoad';
import HelpDesk from './HelpDesk';
import KeyContacts from './KeyContacts';
import LoadHistory from './LoadHistory';
import MaintenanceVids from './MaintenanceVids';
import Payroll from './Payroll';
import PersonalAdvance from './PersonalAdvance';
import PrePlanned from './PrePlanned';
import PreTrip from './PreTrip';
import TrailerTracking from './TrailerTracking'
import SafetyVids from './SafetyVids';
import ServiceLocations from './ServiceLocations';
import BorderInspections from './BorderInspections';
import PayrollPayChecks from './PayrollPayChecks';
import PayrollFAQ from './PayrollFAQ';
import PayrollContacts from './PayrollContacts';
import LoadDetail from './LoadDetail';
import Safety from './Safety';
import SafetyDocs from './SafetyDocs';
import SafetyContacts from './SafetyContacts';
import PaycheckDetail from './PaycheckDetail';
import TrainingDay from './TrainingDay';
import TrainingContacts from './TrainingContacts';
import TraineeProfile from './TraineeProfile';
import ReleaseTrainee from './ReleaseTrainee';
import DailyReports from './DailyReports';
import BackingEvents from './BackingEvents';
// import RoadTestHistory from './RoadTestHistory';
import RoadTest from './RoadTest';
//import Quizzes from './Quizzes';
import Agreements from './Agreements';
import NewTrainee from './NewTrainee';
import Referral from './Referral'
import PhotoUpload from './PhotoUpload';
import Benefits from './Benefits';
import OrientationDrivers from './OrientationDrivers'
import OrientationRetest from './OrientationRetest'
import PassengerForm from './PassengerForm';
import LeasePurchase from './LeasePurchase';
import Rewards from './Rewards';
import EditProfile from './EditProfile';
import TrainerFeedback from './TrainerFeedback';
import Home from './Home';
import Contacts from './Contacts';
import Load from './Load';
import Support from './Support';
import News from './News';
import Settings from './Settings';
import DivisionInfo from './DivisionInfo';
import NotificationHub from './NotificationHub';
import RegisterPlate from './RegisterPlate';
import Contracts from './Contracts';
import Guide from './Guide';
import DMDriverSelect from './DMDriverSelect';
import AppUrlListener from './AppUrlListener';
import {userStore} from "../api/AuthService";
import {UserType} from "../api/enum/UserType";
import DmSelect from "./DmSelect";
import TraineePersonalProfile from "./TraineePersonalProfile";
import HelpDirect from './HelpDirect';
import Permits from './Permits';
import YardDog from './YardDog';
import GuardDog from './GuardDog';
import Trip from './Trip';
import {UserUtils} from "../utils/UserUtils";
import SafetyScore from "./SafetyScore";
import Money from './Money';
import Docs from './Docs';
import Registrations from './Registrations';
import CabinPhoto from './CabinPhoto';
import DriverHandbook from './DriverHandbook';

export default class Main extends React.Component {

  render() {

    function getHomePageUrl() {
      switch (userStore.getState().type) {
        case UserType.INSPECTOR:
          return '/borderinspections';
        case UserType.YARDDOG: 
          return '/yarddog';
        case UserType.GUARDDOG:
          return '/guarddog';
        case UserType.DRIVER_MANAGER:
          return '/dmdriverselect';
        case UserType.ADMIN:
          return '/dmselect';
        default:
          return '/home';
      }
    }

    return(
      <IonReactRouter>
        {/* <SideMenu /> */}
        {/* <Header /> */}
        {/* <IonTabs> */}
        <div>
          <AppUrlListener/>
          <IonRouterOutlet> 
            <Route path="/Home" component={Home} exact={true} />
            <Route path="/notifications" component={NotificationHub} exact={true} />
            <Route path="/division" component={DivisionInfo} exact={true} />
            <Route path="/settings" component={Settings} exact={true}/>
            <Route path="/support" component={Support} exact={true} />
            <Route path="/rewards" component={Rewards} exact={true} />
            <Route path="/editprofile" component={EditProfile} exact={true} />
            <Route path="/pretrip" component={PreTrip} exact={true} />
            <Route path="/currentload" component={CurrentLoad} exact={true} />
            <Route path="/loadhistory" component={LoadHistory} exact={true} />
            <Route path="/loadhistory/:loadnum" component={LoadDetail} exact={true} />
            <Route path="/preplanned" component={PrePlanned} exact={true} />
            { checkOS() ? 
              <Route path="/trailertracking" component={TrailerTracking} exact={true} /> : <></>
            }
            <Route path="/breakdown" component={Breakdown} exact={true} />
            <Route path="/pretrip" component={PreTrip} exact={true} />
            <Route path="/photoupload" component={PhotoUpload} exact={true} />
            <Route path="/personaladvance" component={PersonalAdvance} exact={true} />
            <Route path="/payroll" component={Payroll} exact={true} />
            <Route path="/paychecks" component={PayrollPayChecks} exact={true} />
            <Route path="/paychecks/:checkDate" component={PaycheckDetail} exact={true} />
            <Route path="/paychecks/:checkDate/:loadNumber" component={PaycheckDetail} exact={true} />
            <Route path="/payrollfaq" component={PayrollFAQ} exact={true} />
            <Route path="/payrollcontacts" component={PayrollContacts} exact={true} />
            <Route path="/orientationroadtests" component={OrientationDrivers} exact={true} />
            <Route path="/retest" component={OrientationRetest} exact={true} />
            <Route path="/leasepurchase" component={LeasePurchase} exact={true} />
            <Route path="/referral" component={Referral} exact={true} />
            <Route path="/inbox" component={Inbox} />
            <Route path="/news" component={News} />
            <Route path="/cabinphoto" component={CabinPhoto} />
            <Route path="/guide" component={Guide}/>
            <Route path="/safety" component={Safety} />
            <Route path="/permits" component={Permits} />
            <Route path="/benefits" component={Benefits} exact={true} />
            <Route path="/safety/videos" component={SafetyVids} />
            <Route path="/safety/documents" component={SafetyDocs} />
            <Route path="/safety/contacts" component={SafetyContacts} />
            <Route path="/driverhandbook" component={DriverHandbook} />
            <Route path="/maintenancevids" component={MaintenanceVids} />
            <Route path="/servicelocations" component={ServiceLocations} />
            <Route path="/accidentinfo" component={AccidentInfo} />
            <Route path="/dmdriverselect" component={DMDriverSelect} />
            <Route path="/dmselect" component={DmSelect} />
            <Route path="/borderinspections" component={BorderInspections} />
            <Route path="/yarddog" component={YardDog} />
            <Route path="/guarddog" component={GuardDog} />
            <Route path="/helpdesk" component={HelpDesk} />
            <Route path="/chainofcommand" component={ChainOfCommand} />
            <Route path="/passenger" component={PassengerForm} />
            <Route path="/helpdirect" component={HelpDirect} />
            <Route path="/keycontacts" component={KeyContacts} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/load" component={Load} />
            <Route path="/trip" component={Trip} />
            <Route path="/money" component={Money} />
            <Route path="/docs" component={Docs} />
            <Route path="/registervehicle" component={RegisterPlate}/>
            {/* <Route path="/HowToDrive" component={HowToDrive} /> */}
            <Route path="/contracts" component={Contracts} />
            <Route path="/trainingday" component={TrainingDay} />
            <Route path="/traineeprofile" component={TraineeProfile} />
            <Route path="/releasetrainee" component={ReleaseTrainee} />
            <Route path="/dailyreports" component={DailyReports} />
            <Route path="/backingevents" component={BackingEvents} />
            {/* <Route path="/roadtesthistory" component={RoadTestHistory} /> */}
            <Route path="/trainingroadtest" component={RoadTest}/>
            <Route path="/trainingcontacts" component={TrainingContacts}/>
            {/* <Route path="/quizzes" component={Quizzes} /> */}
            {/* <Route path="/courseoverview" component={CourseOverview} /> */}
            <Route path="/agreements" component={Agreements} />
            <Route path="/newtrainee" component={NewTrainee} />
            <Route path="/registrations" component={Registrations} />
            <Route path="/safetyscore" component={SafetyScore} />
            <Route path="/registrations" component={Registrations} />
            {UserUtils.isTrainee() ? <Route path="/trainee-personal-profile" component={TraineePersonalProfile}/> : <></>}
            {UserUtils.isTrainerFeedbackEnabled() ? <Route path="/trainer-feedback" component={TrainerFeedback}/> : <></>}
            <Route path="/"
                   render={() => <Redirect to={getHomePageUrl()}/>}
                   exact={true}/>
          </IonRouterOutlet>
          </div>
          {/* <NavBar /> */}
          
          {/* <IonTabBar slot="bottom">
            <IonTabButton tab="Work" href="/Work">
              <IonIcon icon={speedometer} />
              <IonLabel>Work</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Money" href="/Money">
              <IonIcon icon={cash} />
              <IonLabel>Money</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Inbox" href="/Inbox">
              <IonIcon icon={mail} />
              <IonLabel>Inbox</IonLabel>
            </IonTabButton>
            {localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "admin" ? 
            <IonTabButton tab="Training" href="/Training">
              <IonIcon icon={school} />
              <IonLabel>Trainer</IonLabel>
            </IonTabButton> : null}
            {localStorage.getItem('type') === "instructor" || localStorage.getItem('type') === "trainer" || localStorage.getItem('type') === "admin" ? 
            <IonTabButton tab="Orientation" href="/Orientation">
              <IonIcon icon={ribbon} />
              <IonLabel>Orientation</IonLabel>
            </IonTabButton> : null}
            <IonTabButton tab="Help" href="/Help">
              <IonIcon icon={help} />
              <IonLabel>Help</IonLabel>
            </IonTabButton>
          </IonTabBar> */}
        {/* </IonTabs> */}
      </IonReactRouter>
        );
    }
}
