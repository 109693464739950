import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {close} from 'ionicons/icons';
import {config} from '../Constants'
import documentIcon from "../imgs/document.png"
import {ApiService} from "../api/ApiService";
import {DocumentInfo} from "../api/model/DocumentsList";
import {VideoInfo} from "../api/model/VideosList";

const Permits : React.FC = () => {

  const [showModalDoc, setShowModalDoc] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false)
  const [doc, setDoc] = useState<DocumentInfo>();
  const [docs, setDocList] = useState<DocumentInfo[]>([]);
  const [selectedDocPages, setSelectedDocPages] = useState<any>([]);
  const [vidLink, setVidLink] = useState("");
  const [vidIds, setVidIds] = useState<VideoInfo[]>();

  useEffect(() => {
    ApiService.getDocsList("permit", {type: 'permit', isLp: 0}).then(it => setDocList(it));
    ApiService.sendEventCommand("Checked Permit", "eventCheck", "User checked the permits page");
  }, []);

  function prepDoc(doc: any) {
    let tempArray: number[] = Array.from(Array(doc.pages).keys())
    setSelectedDocPages(tempArray);
    console.log("prepped pages", doc, tempArray);
    setShowModalDoc(true);
  }

  return (
    <IonPage >
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Permits</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent  fullscreen>

        <IonModal isOpen={showModalDoc} cssClass='my-custom-class'>

        <IonHeader >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={ function() { setShowModalDoc(false); }}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent>
            <div style={{ height: '100vh'}}>
                  {/* <img src={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+"1"+'.'+doc?.extension}/> */}
                {selectedDocPages.map(function(pages: number) {
                  return <img src={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+pages+'.'+doc?.extension} alt={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+"_"+pages+'.'+doc?.extension} style={{ height: 'auto', width: '100%', zoom:"10%"}}/>
                    {/* <Markup content={doc?.content} /> */}
                })}
              </div>
          </IonContent>
        </IonModal>
        <IonModal isOpen={showModalVideo} cssClass='my-custom-class'>
        <IonHeader >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={ function() { setShowModalVideo(false); setVidLink("")}}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent>
          <iframe id="video-modal" title="safety video" width="100%" height="345" src={vidLink}>
                </iframe>
          </IonContent>

        </IonModal>
        <div className='main-30-70'>
          <div>
       <IonList style={{ padding: '8px'}}>
       <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
       {docs.map(function(doc, idx){
         let id = doc.id
         let title = doc.title
          return config.isPlatformSci ? 
            <IonItem key={id} onClick={() => {setDoc(doc); prepDoc(doc)}} lines="none">
              <div className='item-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <p>{title}</p>
                <img src={documentIcon} className="link-icon" alt="doc"/>
              </div>
              {/* <IonLabel text-wrap>{title}</IonLabel>
              <IonIcon slot="end" icon={chevronForward}></IonIcon> */}
            </IonItem> : null
        })} 
        </IonList></div>
        </div>
      
      
      </IonContent>
    </IonPage>
  );
};

export default Permits;

