import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonItem, IonItemDivider, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {config} from '../Constants'
import img1 from '../imgs/benefit_1.png';
import img2 from '../imgs/benefit_2.png';
import img3 from '../imgs/benefit_3.png';
import img4 from '../imgs/benefit_4.png';
import img5 from '../imgs/benefit_5.png';
import img6 from '../imgs/benefit_6.png';
import documentIcon from "../imgs/document.png"
import {ApiService} from "../api/ApiService";
import {DocumentInfo} from "../api/model/DocumentsList";

const Benefits : React.FC = () => {
  const [showModalDoc, setShowModalDoc] = useState(false);
  const [noDocFound, setNoDocFound] = useState(false);
  const [docs, setDocList] = useState<DocumentInfo[]>([]);
  const [driverHash, setDriverHash] = useState('')
  const [doc, setDoc] = useState<DocumentInfo>();

    useEffect(() => {
        ApiService.getDriverSsn("benefits").then(it => {
            setDriverHash(it);
            checkLink(it);
        });
        ApiService.getDocsList("benefits", {type: 'hr', isLp: 0}).then(it => setDocList(it));
    }, []);

  const checkLink = (link: any) => {
    const requestOptions = {
      method: 'GET',
      headers: {}
    };
    fetch('https://westernexpressmobile.com/agreements/' + link + "-0.pdf", requestOptions)
    .then(response => {
      if(response.status === 404){console.log("404");}})
    .catch(function(err) {
      console.log(err);
      setNoDocFound(true);
    })
  }

  // Unused
  // const agreementHandler = () => {
  //   if (noDocFound) {
  //     setToast("No Contract Found");
  //     setShowToast(true)
  //   } else {
  //     window.open('https://westernexpressmobile.com/agreements/' + driverHash + "-0.pdf", '_blank')
  //   }
  // }

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Benefits</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
    <IonList style={{marginBottom: "9vh", padding: '8px'}}>
    <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
    {docs.length ? null : <IonItem lines="none"><div style={{width: "100%", textAlign: "center"}}>
                            <h5><b>No Documents</b></h5>
                        </div></IonItem>}
    {docs.map(function(doc, idx){
         let id = doc.id
         let title = doc.title
          return config.isPlatformSci ?
            <IonItem key={id} onClick={() => {setDoc(doc); setShowModalDoc(true)}} lines="none">
              <div className='item-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <p>{title}</p>
                <img src={documentIcon} className="link-icon" alt="doc"/>
              </div>
              {/* <IonLabel text-wrap>{title}</IonLabel>F
              <IonIcon slot="end" icon={chevronForward}></IonIcon> */}
            </IonItem> :
            <a href={'https://westernexpressmobile.com/docs2/'+doc?.type+'_'+doc?.id+'.'+doc?.extension+"#toolbar=0"} target="_blank" style={{textDecoration: "none"}}>
              <IonItem key={id} onClick={() => {setDoc(doc);}} lines="none" style={{ margin: "8px 0", borderRadius: "10px"}} color="white">
              <div className='item-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor:"#ffffff"}}>
                <p>{title}</p>
                <img src={documentIcon} className="link-icon" alt="doc"/>
              </div>
                {/* <IonLabel text-wrap>{title}</IonLabel>
                <IonIcon slot="end" icon={chevronForward}></IonIcon> */}
              </IonItem>
            </a>
        })}
    </IonList>
      {/* <IonList style={{ padding: '8px'}}>
            <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
            {config.isPlatformSci ?
              <div onClick={agreementHandler} style={{textDecoration: "none"}}>
                <IonItem onClick={() => {setSelectedDoc(doc); setShowModalDoc(true)}}>
                  <IonLabel text-wrap>Training Agreement</IonLabel>
                  <IonIcon slot="end" icon={chevronForward}></IonIcon>
                </IonItem> 
              </div>  :

              <div onClick={agreementHandler} style={{textDecoration: "none"}}>
                <IonItem onClick={() => {setSelectedDoc(doc);}}>
                  <IonLabel text-wrap>Training Agreement</IonLabel>
                  <IonIcon slot="end" icon={chevronForward}></IonIcon>
                </IonItem>
              </div>
            }
      </IonList> */}
        <div style={{padding: "4%"}}>
            <h1 style={{color:'black', marginBottom:"14px", marginTop:"7px", borderBottom:"1px solid grey", textAlign: "center"}}>Enrolling in Benefits Using Workday</h1>
            { !config.isPlatformSci ?
            <IonItemDivider>
                <h3>You can access Workday: <a href="https://wd5.myworkday.com/westernexp/d/Home.htmld" style={{color:"black", textAlign: "center"}}>Here</a></h3>
            </IonItemDivider> : null
            }
            <h2 style={{marginBottom:"7px", marginTop:"14px", textAlign: "center", color: 'black'}}><i>**As a new employee, you have 30 days to enroll in benefits.</i></h2>
            <IonCard style={{marginBottom: '10vh'}}>
                <IonCardContent style={{backgroundColor: "var(--ion-color-step-100, #e6e6e6)", color: "white"}}>
                    <h3 style={{marginBottom:"7px", marginTop:"7px"}}>- <b>A <i>username</i> and <i>password</i> for Workday were sent to your personal email on file.</b></h3>
                    <h3 style={{marginBottom:"7px", marginTop:"7px", textAlign: 'center', color:"#A9EF01"}}><b>Username: first.lastName (Example: John.Smith)</b></h3>
                    <h3 style={{marginBottom:"7px", marginTop:"7px"}}>- Once you login, follow the steps below to enroll</h3>
                    <h4 style={{marginBottom: "7px", marginTop:"7px"}}><span style={{color:"#A9EF01"}}><b>1.</b></span> Click the mailbox in the top right corner of the screen.</h4>
                    <img alt="step 1" src={img1}/>
                    <img alt="step 2" src={img2}/>
                    <h4 style={{marginBottom: "7px", marginTop:"7px"}}><span style={{color:"#A9EF01"}}><b>2.</b></span> Select whether you are a smoker or not and click continue.</h4>
                    <img alt="step 3" src={img3}/>
                    <h4 style={{marginBottom: "7px", marginTop:"7px"}}><span style={{color:"#A9EF01"}}><b>3.</b></span> Click on each benefit that you want to enroll in. Once you have made all of your selections, click <b><i>“Review & Sign”</i></b> in the bottom left corner. </h4>
                    <img alt="step 4" src={img4}/>
                    <img alt="step 5" src={img5}/>
                    <h3 style={{marginBottom:"7px", marginTop:"7px", color:"#A9EF01"}}><b>IF YOU DO NOT SUBMIT THE ELECTIONS, THEY WILL NOT GO INTO EFFECT AND YOU WILL NOT HAVE COVERAGE.</b></h3>
                    <h2 style={{marginBottom:"7px", marginTop:"7px"}}><b><u>Adding Dependents</u></b></h2>
                    <h3 style={{marginBottom:"7px", marginTop:"7px"}}>Western allows you to add qualifying dependents. Please be aware that you will receive further documentation from Sisco who will confirm eligibility for your dependent. Sisco will reach out to you through the mail. </h3>
                    <h3 style={{marginBottom:"7px", marginTop:"7px", color:"#A9EF01"}}><b>IT IS IMPORTANT TO SEND THE REQUESTED INFORMATION TO THEM OR YOUR DEPENDENTS MAY BE DROPPED.</b></h3>
                    <h4 style={{marginBottom: "7px", marginTop:"7px"}}><i>*Western does not allow spouses who have insurance available through their employer, or children who you are not your own. Birth certificates will be required.</i></h4>
                    <h4 style={{marginBottom: "7px", marginTop:"7px"}}>After you select your medical plan and click continue, you will be asked if you want to add your dependent. Follow the steps below to add them.</h4>
                    <img alt="step 6" src={img6}/>
                </IonCardContent>
            </IonCard>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Benefits;
